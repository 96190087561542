import './App.css';
import Container from '@mui/material/Container';
import {StyleSheet } from 'react-native';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import StartPage from './pages/StartPage';
import QuestionarePage from './pages/QuestionarePage';
import About from './pages/AboutProject';
import Joinus from './pages/JoinusPage';
import ResponsiveAppBar from './components/AppBar';
import { Box, Stack, Typography, Link } from '@mui/material';
import { Divider } from '@mui/material';
//export default i18n;
import nii_logo from './logos/nii_logo.png';
import esrc_logo from './logos/esrc_logo.png';
import jsps_logo from './logos/jsps_logo.png';
import hwu_logo from './logos/hwu_logo.png';




function App() {
  const styles = StyleSheet.create({
    stretch: {
      height: "30px",
      resizeMode: 'contain',
      marginLeft:15,
    },
  });

  return (
    <Router>
      <ResponsiveAppBar />
      <Container>
        <Box sx={{ marginY:3,minHeight:300 , marginRight:'auto'}}>
          <Routes>
            <Route exact path='/' element={<StartPage/>}></Route>
            <Route exact path='/about' element={<About/>}></Route>
            <Route exact path='/participant' element={<QuestionarePage/>}></Route>
            <Route exact path='/joinus' element={<Joinus/>}></Route>
          </Routes>
        </Box>
        <Divider variant="middle" />
        <Box sx={{ marginTop:3, fontSize:12, color:"grey", textAlign: "center" }}>
          <Box sx={{ height:"100%", maxWidth:300,marginRight:'auto'}}>
          <Stack direction="row" spacing={2}>
            <Typography variant="caption" display="block" gutterBottom>
              <Link href="https://research.nii.ac.jp/~bono/en/index.html" color="inherit" underline="hover">NII Bono Lab. 2023</Link>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              <Link href="https://www.cross-sign.nii.ac.jp/seafile" color="inherit" underline="hover">Files</Link>
            </Typography>
          </Stack>
          </Box>
          <Box sx={{ height:"100%",  maxWidth:600, marginLeft:'auto'}}>
              <a href="https://www.hw.ac.uk/"><img src={hwu_logo}  alt="HWU" style={styles.stretch}/></a>;
              <a href="https://www.nii.ac.jp/"><img src={nii_logo}  alt="NII" style={styles.stretch}/></a>;
              <a href="https://esrc.ukri.org/"><img src={esrc_logo}  alt="ESRC" style={styles.stretch}/></a>;
              <a href="https://www.jsps.go.jp/index.html"><img src={jsps_logo}  alt="JSPS" style={styles.stretch}/></a>;
            </Box>
         </Box> 
      </Container>
    </Router>
  );
}

export default App;
