import React, { useState } from 'react';
import { Container, Typography, TextField, Button, FormControl, FormGroup, Checkbox, FormControlLabel, Radio, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PostJoinusFormService from "../services/joinus_form";

const JoinusForm = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    email: '',
    name: '',
    answers: [],
    other: '',
  });

  const [submitted, setSubmitted] = useState(false);

const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === 'answers') {
      // If checkbox, update the array of selected answers
      setFormData({
        ...formData,
        [name]: checked
          ? [...formData[name], value]
          : formData[name].filter((item) => item !== value),
      });
    } else {
      // If not checkbox, update the value normally
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    PostJoinusFormService.upload(formData)
    // Here you can add logic to handle form submission, e.g., sending data to a server
    setSubmitted(true);
  };

  return (
    <Container maxWidth="sm" align="center">
      {submitted ? (
        <Box >
          <Typography variant="h4" align="center" style={{ marginTop: '50px' }}>
            {t("joinus_thnxmsg1")}
          </Typography>
          <br/>
          <Typography variant="h5" align="center" style={{ marginTop: '50px' }}>
          {t("joinus_thnxmsg2")}
        </Typography>
        </Box>

      ) : (
        <form onSubmit={handleSubmit}>
          <Typography variant="h4" align="center" style={{ marginTop: '20px', marginBottom: '30px' }}>
          {t("joinus_title")}
          </Typography>
          <br/>
          <Typography variant="subtitle1">
            {t("joinus_text1")}
           </Typography>
           <br/>
           <Typography variant="subtitle1">
            {t("joinus_text2")}
           </Typography>
           <br/>
           <Typography variant="subtitle1">
            {t("joinus_text3")}
           </Typography>
           <br/>
           <Typography variant="subtitle1">
            {t("joinus_text4")}
           </Typography>
           <br/>
           <Typography variant="subtitle1">
            {t("joinus_text5")}
           </Typography>
           <br/>
          <FormControl component="fieldset" style={{ marginTop: '20px'}}>
            <Typography variant="h6" style={{ marginBottom: '20px'}}>{t("joinus_select_text")}</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={formData.answers.includes('1')} onChange={handleChange} name="answers" value="1" />}
                label={t("joinus_op1")}
              />
              <FormControlLabel
                control={<Checkbox checked={formData.answers.includes('2')} onChange={handleChange} name="answers" value="2" />}
                label={t("joinus_op2")}
              />
              <FormControlLabel
                control={<Checkbox checked={formData.answers.includes('3')} onChange={handleChange} name="answers" value="3" />}
                label={t("joinus_op3")}
              />
              <FormControlLabel
                control={<Checkbox checked={formData.answers.includes('4')} onChange={handleChange} name="answers" value="4" />}
                label={t("joinus_op4")}
              />
              <TextField
                label={t("joinus_opot")}
                variant="standard"
                name="other"
                value={formData.other}
                onChange={handleChange}
              />
            </FormGroup>
          </FormControl>

          <TextField
            label={t("joinus_email")}
            fullWidth
            variant="outlined"
            margin="normal"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <TextField
            label={t("joinus_name")}
            fullWidth
            variant="outlined"
            margin="normal"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
          {t("joinus_subbotton")}
          </Button>
        </form>
      )}
    </Container>
  );
};

export default JoinusForm;