import React, { Component } from "react";
//import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import { Box} from '@mui/material';


import ManualItem from "./ManualItem";
import { man_items } from "../manual_data";

const Manual = () => {
  //const { t, i18n } = useTranslation();

  
  return class extends Component {
    //constructor(props) {
     //   super(props);
      //}

    render(){

        let mi = [];
        for (let i = 0; i < man_items.length; i++) {
            var manI = {"title":man_items[i].title,
                            "text":man_items[i].text,
                            "videoId":man_items[i].video_id};
            mi.push(<ManualItem manItem={manI} key={i}/>);
        }

        return (
            <Box>
                <List>
                {mi}               
                </List>
            </Box>
        );}
}}

export default Manual;