import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import SubmitDialog from "../components/SubmitDialog"
import SubmitForm from '../components/SubmitForm';
import Manual from '../components/Manual';
import Terms from '../components/Terms';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import React from "react";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function QuestionarePage() {
  var terms_link_dl = "https://www.cross-sign.nii.ac.jp/seafile/f/0d0f1e0030a74f3ab945/?dl=1"
  //var terms_link_view = "https://www.cross-sign.nii.ac.jp/seafile/f/0d0f1e0030a74f3ab945/"
  
  var manual_link_view = "https://www.cross-sign.nii.ac.jp/seafile/f/3581a0de54c94e0da6a9/"
  var manual_link_dl = "https://www.cross-sign.nii.ac.jp/seafile/f/3581a0de54c94e0da6a9/?dl=1"

  const [value, setValue] = React.useState(0);
  const { t} = useTranslation();
  const SD = SubmitDialog();
  const SF = SubmitForm();
  const MN = Manual();
  
  const [checked, setChecked] = React.useState(false);

  const [enabled, setEnabled] = React.useState(true);

  const handleAgree = (event) => {
      setChecked(event.target.checked);
  };
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const nextTab = (event, newValue) => {
    setValue(value+1);
  };

  const handleViewManual = (event) => {
    event.preventDefault();
    window.open(manual_link_view);
  };
  
  const handleScroll = (event) => {
    const target = event.target;
    if (target.scrollHeight - target.scrollTop === target.clientHeight){
      //console.log("scr down")
      setEnabled(event.target.enabled)
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} variant="fullWidth" centered>
          <Tab label="1. 実験参加説明書" {...a11yProps(0)} sx={{fontSize: 18,fontWeight: 600}} />
          <Tab label="2. 実験参加同意書" {...a11yProps(1)} sx={{fontSize: 18,fontWeight: 600}} />
          <Tab disabled={!checked} label={"3. "+t("qq2_h3")} {...a11yProps(2)} sx={{fontSize: 18,fontWeight: 600}}/>
          <Tab disabled={!checked} label={"4. "+t("qq1_h3")} {...a11yProps(3)} sx={{fontSize: 18, fontWeight: 600}}/>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
      <Box>
          <br/>
          <h2>実験参加説明書</h2>
          <br/>
          <h3>本資料は日本手話動画，書記日本語がございます．</h3>
          <br/>
          <Box>
                <Box sx={{maxWidth:800, marginX:'auto'}}>
                    <Button variant="contained" onClick={handleViewManual} size="large" color="success" sx={{margin:3}} startIcon={<VisibilityIcon/>} endIcon={<OpenInNewIcon/>}>
                    実験参加説明書（書記日本語）.PDF
                    </Button>
                    <Button variant="contained" href={manual_link_dl} size="large" color="success" sx={{margin:3}} startIcon={<DownloadIcon/>}>
                    実験参加説明書ダウンロード .PDF
                    </Button>
                </Box>
                <Box sx={{maxWidth:"80%", marginX:'auto'}}>
                    <iframe width="100%" height="520" src="https://www.youtube.com/embed/?listType=playlist&list=PLdQkGg1wDVcXrMSn9i5CJ4ooTs5oqWPgj&modestbranding=1&controls=1&autoplay=0&playsinline=1&cc_load_policy=1&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture" allowfullscreen></iframe>
                    
                </Box>
                <Box sx={{maxWidth:"10%",marginX:'auto', marginY:3}}>
                  <Button variant="contained" onClick={nextTab} size="large" color="success" endIcon={<NavigateNextIcon/>}>
                  {t("cc3_btn")}
                  </Button>
                </Box>
          </Box> 
        </Box> 
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box>
          <Box sx={{maxWidth:"80%", marginX:'auto'}}>
            <iframe width="100%" height="520" src={"https://www.youtube-nocookie.com/embed/aUqrljTEMbE?&modestbranding=1&controls=1&autoplay=0&playsinline=1&cc_load_policy=1"} title="YouTube video player" frameborder="0" allow="autoplay; clipboard-write; encrypted-media;" allowfullscreen="true"></iframe>
          </Box>
          <Box sx={{ marginY:3 }} >
            <div >
            <Box sx={{maxHeight:600,overflowY:'scroll',scrollBehaviour:'smooth'}} onScroll={handleScroll} >
            <Terms/>
            </Box>
            </div>
            
            <br/>
            <Box>
              <FormControlLabel control={<Checkbox disabled={enabled} checked={checked} onChange={handleAgree} inputProps={{ 'aria-label': 'controlled' }} color="success" />} label="以上の説明を十分理解し、上記の実験に参加することに同意します。"/>
            </Box>
            <br/>
            <Button variant="contained" href={terms_link_dl} size="large" color="success" startIcon={<DownloadIcon/>}>
              実験参加同意書ダウンロード .PDF
            </Button>
            <Box sx={{maxWidth:"10%",marginX:'auto', marginY:3}}>
                  <Button variant="contained" disabled={!checked} onClick={nextTab} size="large" color="success" endIcon={<NavigateNextIcon/>}>
                  {t("cc3_btn")}
                  </Button>
                </Box>
          </Box> 
        </Box>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <h3>{t("qq2_h3")}</h3>
          <br/>
          <a>{t("qq2_a")}</a>
          <Box sx={{ marginY:3 }}>
            <SF/>
          </Box>
          <Box sx={{maxWidth:"10%",marginX:'auto', marginY:3}}>
            <Button variant="contained" disabled={!checked} onClick={nextTab} size="large" color="success" endIcon={<NavigateNextIcon/>}>
            {t("cc3_btn")}
            </Button>
          </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Box>
            <h3>{t("qq1_h3")}</h3>
            <Box sx={{marginY:3}}>
              <Box sx={{maxWidth:800}}>
                  <a>{t("qq1_a_1")}</a>
                  <br/>
                  <Button variant="contained" href={t("qq1_url_b1")} size="large" color="success" target='_blank' startIcon={<OpenInNewIcon/>} sx={{margin:3}}>
                  {t("qq1_B_1")}
                  </Button>
                  <br/>
                  <a>{t("qq1_a_2")}</a>
                  <br/>
                  <Button variant="contained" href={t("qq1_url_b2")} size="large" color="success" target='_blank' startIcon={<OpenInNewIcon/>} sx={{margin:3}}>
              {t("qq1_B_2")}
              </Button>
                </Box>

            </Box> 
          </Box> 
      </TabPanel>
    </Box>
  );
}