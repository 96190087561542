//import Paper from '@mui/material/Paper';
//import Grid from '@mui/material/Grid';
//import { Button } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AboutCCard = ({card}) => {
    const { t} = useTranslation();
    var vid_id = t("about_video_id")
    var video_url = "https://www.youtube-nocookie.com/embed/"+vid_id+"?&modestbranding=1&controls=0&autoplay=0&playsinline=1&cc_load_policy=1&rel=0"
    return( 

        <Box sx={{ minHeight:300, padding:2}}>
            <Box >
                <h2>{card.text}</h2>
            </Box>
            <Box sx={{ paddingY:2 }} >
            <iframe width="100%" height="420" src={video_url} title="YouTube video player" frameborder="0" allow="autoplay; clipboard-write; encrypted-media;" allowfullscreen="false"></iframe>
            <br/>
                <br/>
                <a>
                {card.text_2}
                </a>
                <br/>
                <br/>
                <Button  href={card.path} size="large" color="success">
                    {card.link}
                </Button>
            </Box>
    
        </Box>
      
        
    
    );
};

export default AboutCCard;