export const EN = { "cc1_h2": "The Cross-Sign Project",
             "cc1_link": "Learn about the Cross-sign Project",
             "cc2_h2": "For Participants",
             "cc2_link": "Participate here",
             "cc3_btn": "Next",


             "about_title": "About our Project",
             "about_video_id":"9ZH6iHdESS0",
             "about_part_1": " This three year joint research project is led in the UK by Sujit Sahasrabudhe (Research Associate) and Dr Robert Adam (Principal Investigator), in collaboration with colleagues at the National Institute of Informatics in Japan. This project commenced in March 2022 and is focused on investigating the impact of video conferencing use during the Covid-19 pandemic on how deaf people sign. The focus of the project is British Sign Language (BSL) and Japanese Sign Language (JSL). The two sign languages have their own distinctive features, for example different fingerspelling systems and different handshapes for the same kind of depicting signs (ie classifier signs) and different ways of incorporating iconicity.",
             "about_part_2":"We study how deaf people’s BSL/JSL variations are affected by video conferencing in the UK and Japan, in the following way:",
             "about_part_2_1":"One hundred deaf people will be surveyed through the use of a questionnaire about their language attitudes pre-, during and post-pandemic.",
             "about_part_2_2":"Ten deaf people in the UK of varying backgrounds (race, gender, age and gender) will meet online and their conversations will be recorded for analysis of their signing styles.",
             "about_part_2_3":"These ten deaf people will meet with deaf people from Japan online via video conferencing online.",
             "about_part_2_4":"We will look at how these deaf people sign to each other. With a focus on cross-signing and translanguaging, we will use conversation analysis (CA) to look at the data. We will look at how deaf people in Britain and in Japan accommodate each other during these dialogues in order to understand their conversation partners.",
             "about_part_2_5":"All video recorded data from the dialogues will make up a corpus of cross-signing, which will subsequently be used for machine learning of sign languages (or artificial intelligence) so computers can be used to detect sign movements and handshapes in a subsequent project..",

            "qq1_h2":"Thank you for joining our research",

            "qq1_h3":"Reply to the survey",
            "qq1_url":"https://docs.google.com/forms/d/e/1FAIpQLScsd3XiNu-0xqY4h3G5GqSptiJPVMz0VwHL5YEMHMQvw2Ipdg/viewform?fbzx=-6922903128869851396&pli=1",

            "qq1_url_b1":"https://forms.gle/hv143iFH34Uau7xU8",

            "qq1_url_b2":"https://forms.gle/G87FBN8enR2F8cRg9",

            "qq1_w": "If you are participant, here you can submit your recordings and answer the survey.",

            "qq1_a_1": "First time users: Click the link below to complete the survey",
            
            "qq1_a_2": "For second and subsequent visits: Click the link below to complete the survey",

            "qq1_B_1": "First Time Survey",
            "qq1_B_2": "Non-First Time Survey",

            "qq1_a": "Follow the link below to answer the survey",
            "qq1_B": "Link to the survey",

            "qq2_h3": "Submit your recording",
            "qq2_a": "If you have recorded conversation, please submit using the button below. It will reveal the submission form.",
            

            "sd_T":"Submit a Recording",
            "sd_B":"Submit a recording",
            "sd_DCT1":"Please input your Participant ID and Submission Password",

            "sd_L1":"Participant ID",
            "sd_L2":"Submission Password",

            "sd_DCT2":"Please attach your recording file",
            "sd_B2":"Choose Recording Video .mp4 File",

            "sd_DCT2_chat":"(OPTIONAL) Please attach your chat file",
            "sd_B2_chat":"Choose Chat .txt File",
            
            "sd_DCT3":"After the recording is uploaded please close the form",
            "sd_B3":"Close",
            "sd_B4":"Upload",

            "Wrong password":"Wrong password",
            "Wrong file type":"Wrong file type",
            "Uploaded the file successfully!":"Uploaded the file successfully!",
            "Uploaded the files successfully!":"Uploaded the files successfully!",
            "The form is incomplete":"The form is incomplete",

            "ev_ttl":"Our Events",
            "ev_text":"Please see our labs' recent events",
            "ev_link":"https://research.nii.ac.jp/~bono/en/event/index.html",
            "ev_button":"Events Page",

            "joinus_title":"We need your help!",
            "joinus_text1":"For our research, we need help from the deaf community",
            "joinus_text2":"There are so few of us",
            "joinus_text3":"Our only chance is if we unite our forces",
            "joinus_text4":"We cannot do it without your help!",
            "joinus_text5":"Join our helping force!",
            "joinus_select_text":"How do you want to help us?",
            "joinus_op1":"I want help make annotations",
            "joinus_op2":"I want to provide data!",
            "joinus_op3":"I want to test your AI models!",
            "joinus_op4":"I want to help recording videos!",
            "joinus_opot":"Describe your own option",
            "joinus_email":"Email",
            "joinus_name":"Name",
            "joinus_subbotton":"Submit",
            "joinus_thnxmsg":"Thank you! We will get in touch with you later!",

             }
export const JP = {
             "cc1_h2": "クロスサインプロジェクト",
             "cc1_link": "クロスサインプロジェクトについて学ぶ",
             "cc2_h2": "参加者向け",
             "cc2_link": "参加する",
             "cc3_btn": "次へ",

             "about_title": "私たちのプロジェクトについて",
             "about_video_id":"x11NO-Mefk4",
             "about_part_1": "COVID-19の世界的大流行により，世界中のろう者がテレビ会議システムを介してオンラインコミュニケーションをとることが日常的になりました．この状況は，手話言語同士の接触を誘発し，新しい手話語彙や手話表現の出現を巻き起こす可能性があります．本研究の目的は，オンラインコミュニケーションによって，ろう者の手話やコミュニケーションスタイルがどのような影響を受けるのかを，手話言語の系統が全く異なるイギリス手話（BSL）と日本手話（JSL）に着目して調査することです．本研究は，異なる手話言語を用いるろう者による対話の際，言語の違いをどのように乗り越えるのかについて，具体的なろう者のアプローチを理解し，言語学における理論的基礎を提供するでしょう．本研究の成果は，世界中のろう者が自由におしゃべりしたり，議論したりする環境を構築する上で参考になる点が多々あると考えています．",
             "about_part_2":"本研究は2021年12月から2024年11月までの3年間，文部科学省の国際共同研究事業「英国との国際共同研究プログラム（JRPs-LEAD with UKRI）」の一環として実施されています．研究課題名は「コロナ禍/コロナ後におけるオンライン会議状況でのクロスサイニング現象の理解」といいます．日本側の研究代表者は坊農真弓（聴者，国立情報学研究所），イギリス側の研究代表者はロバート・アダム（ろう者，ヘリオットワット大学，英国スコットランド)が務めています.以下の方法で研究しています．",
             "about_part_2_1":"100人の聴覚障害者を対象に，パンデミック前・中・後の言語意識についてアンケートを実施します．",
             "about_part_2_2":"日本のさまざまな地域に居住する9名のろう者がオンラインで会い，彼らの会話を録音して手話のコミュニケーションスタイルを分析します（2022年度予定）．",
             "about_part_2_3":"そして，この9人は，イギリスのろう者ともオンラインで会話します（2023年度予定）．",
             "about_part_2_4":"これらの日英のろう者がお互いにどのように手話をするのかを観察していきます．具体的にはクロスサインとトランスランゲージングに焦点を当て，会話分析(CA)を使ってデータを見ていきます．会話相手を理解するために，異なる手話言語を用いるイギリスと日本のろう者が対話の中でどのように問題解決していくのかを観察していきます",
             "about_part_2_5":"オンライン対話のビデオ録画データはすべてクロスサインの言語コーパスとして保存されます．また同時に手話言語の機械学習（または人工知能）に利用され，次のプロジェクトではコンピュータで手話の動きや手の形を検出することができるようになる予定です．",

            "qq1_h2":"研究に参加していただきありがとうございます",
            "qq1_h3":"アンケートへの回答",
            "qq1_url":"https://docs.google.com/forms/d/e/1FAIpQLScsd3XiNu-0xqY4h3G5GqSptiJPVMz0VwHL5YEMHMQvw2Ipdg/viewform?fbzx=-6922903128869851396&pli=1",

            "qq1_url_b1":"https://forms.gle/hv143iFH34Uau7xU8",

            "qq1_url_b2":"https://forms.gle/G87FBN8enR2F8cRg9",

            "qq1_w": "参加者は、ここから調査に参加できます。",

            "qq1_a": "アンケートに回答するには、以下のリンクをクリックしてください",
            
            "qq1_a_1": "初回の場合: アンケートに回答するには、以下のリンクをクリックしてください",
            
            "qq1_a_2": "2回目以降の場合: アンケートに回答するには、以下のリンクをクリックしてください",

            "qq1_B": "アンケートへのリンク",
            "qq1_B_1": "初回",
            "qq1_B_2": "2回目以降",


            "qq2_h3": "ビデオファイルを送信",
            "qq2_a": "下のボタンを使用してビデオファイルを送信してください。 提出フォームが表示されます。",


            "sd_T": "録音を送信",
            "sd_B": "録音を送信",
            "sd_DCT1":"参加者IDと送信パスワードを入力してください",
            
            "sd_L1":"参加者ID",
            "sd_L2":"送信パスワード",

            "sd_DCT2":"ビデオファイルを添付してください",
            "sd_B2": "ビデオファイルを選択",

            "sd_DCT2_chat":"(オプション) チャットファイルを添付してください",
            "sd_B2_chat":"チャットファイルを選択",


            "sd_DCT3":"ビデオファイルがアップロードされたら、フォームを閉じてください",
            "sd_B3":"閉じる",
            "sd_B4":"アップロード",

            "Wrong password":"間違ったパスワード",
            "Wrong file type":"間違ったファイルタイプ",
            "Uploaded the file successfully!":"ファイルが正常にアップロードされました！",
            "Uploaded the files successfully!":"ファイルが正常にアップロードされました！",
            "The form is incomplete":"フォームが不完全です",


            "ev_ttl":"イベント",
            "ev_text":"研究室の最近の出来事をご覧ください",
            "ev_link":"https://research.nii.ac.jp/~bono/ja/event/index.html",
            "ev_button":"イベント一覧",

            "joinus_title": "あなたの助けが必要です！",
            "joinus_text1": "私たちの研究には、ろう者コミュニティの助けが必要です",
            "joinus_text2": "私たちが少なすぎます",
            "joinus_text3": "私たちの唯一のチャンスは、力を合わせることです",
            "joinus_text4": "あなたの助けなしではできません！",
            "joinus_text5": "私たちの助けの力に加わってください！",
            "joinus_select_text": "どのように私たちを助けたいですか？",
            "joinus_op1": "注釈を付けるのを手伝いたいです",
            "joinus_op2": "データを提供したいです！",
            "joinus_op3": "AIモデルをテストしたいです！",
            "joinus_op4": "ビデオの録画を手伝いたいです！",
            "joinus_opot": "あなた自身のオプションを説明してください",
            "joinus_email": "メール",
            "joinus_name": "名前",
            "joinus_subbotton": "提出する",
            "joinus_thnxmsg1": "ありがとうございます！",
            "joinus_thnxmsg2": "後でご連絡させていただきます！"
}