import { useTranslation } from 'react-i18next';
import { Box} from '@mui/material';
const About = () => {
  const { t } = useTranslation();
  return (
    <div>
    <h2>{t("about_title")}</h2>
    <br/>
    <br/>
    <a>{t("about_part_1")}</a>
    <br/>
    <br/>
    <a>{t("about_part_2")}</a>
    <br/>
    <br/>
    <Box sx={{ paddingLeft:5 }}>
    <ul>
      <li>{t("about_part_2_1")}</li>
      <li>{t("about_part_2_2")}</li>
      <li>{t("about_part_2_3")}</li>
      <li>{t("about_part_2_4")}</li>
      <li>{t("about_part_2_5")}</li>
      </ul>
    </Box>
    </div>
  );
}
export default About;