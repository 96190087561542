import React from "react";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box} from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

const ManualItem = ({manItem}) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  var vid_id = manItem.videoId
  var video_url = "https://www.youtube-nocookie.com/embed/"+vid_id+"?&modestbranding=1&controls=1&autoplay=0&playsinline=1&cc_load_policy=1"

  return (
            <Box>
                <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                        <ArticleIcon sx={{ marginRight:1}}/>
                        <OndemandVideoIcon sx={{ marginRight:1}} />
                    </ListItemIcon>
                    <ListItemText primary={manItem.title} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{maxWidth:"80%", marginX:'auto'}}>
                    <iframe width="100%" height="520" src={video_url} title="YouTube video player" frameborder="0" allow="autoplay; clipboard-write; encrypted-media;" allowfullscreen="true"></iframe>
                </Box>
                <Box sx={{ margin:3 }}>
                        <Box>{manItem.text}</Box>
                </Box>
                </Collapse>
            </Box>
        );
};


export default ManualItem;