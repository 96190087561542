import React, { Component } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Stack, Typography } from '@mui/material';
import UploadService from "../services/upload-video";
import LinearProgress from '@mui/material/LinearProgress';
import { withStyles } from "@mui/styles";
import { useTranslation } from 'react-i18next';

const SubmitDialog = () => {
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 15,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: "#EEEEEE",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }))(LinearProgress);

  const { t } = useTranslation();

  return class extends Component {
  constructor(props) {
    super(props);
    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleIdChange = this.handleIdChange.bind(this);
    this.handlePassChange = this.handlePassChange.bind(this);

    this.state = {
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: "",
      isError: false,
      open: false,
      participant_id: "",
      password: "",
      submitted: false,
    };
  }
  

  handleClickOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: "",
      isError: false,
      open: false,
      participant_id: "",
      password: "",
      submitted: false,
    });
  }

  handlePassChange(event) {
    this.setState({
      password: event.target.value,
    });
  }
  
  handleIdChange(event) {
    this.setState({
      participant_id: event.target.value,
    });
  }

  selectFile(event) {
    this.setState({
      selectedFiles: event.target.files,
    });
  }

  upload() {
    let currentFile = this.state.selectedFiles[0];

    this.setState({
      progress: 0,
      submitted: true,
      currentFile: currentFile,
    });

    UploadService.upload(currentFile, this.state.participant_id, this.state.password, (event) => {
      this.setState({
        progress: Math.round((100 * event.loaded) / event.total),
      });
    })
      .then((response) => {
        console.log(response.data.message)
        this.setState({
          message: response.data.message,
          isError: false
        });
      })
      .catch((error) => {
        console.log(error.response.data.message)
        this.setState({
          progress: 0,
          message: error.response.data.message,
          currentFile: undefined,
          isError: true,
          selectedFiles: undefined,
          participant_id: "",
          submitted: false,
        });
      });

    this.setState({
      selectedFiles: undefined,
    });
  }

  render() {
    //const { t } = this.props;
    const {
      selectedFiles,
      currentFile,
      progress,
      message,
      isError,
      open,
      participant_id,
      password,
      submitted,
    } = this.state;

  return (
    <div>
      <Button variant="contained" size="large" color="success" onClick={this.handleClickOpen}>
      {t("sd_B")}
      </Button>
      <Dialog open={open} onClose={this.handleClose}>
        <DialogTitle>{t("sd_T")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {t("sd_DCT1")}
          </DialogContentText>
          <TextField
                required
                disabled={submitted}
                id="participant"
                name="part_id"
                value={participant_id}
                label={t("sd_L1")}
                fullWidth
                margin="dense"
                onChange={this.handleIdChange}
              />
              <Typography variant="inherit" color="textSecondary">
                
              </Typography>
              <TextField
                required
                disabled={submitted}
                id="password"
                name="password"
                value={password}
                label={t("sd_L2")}
                type="password"
                fullWidth
                margin="dense"
                onChange={this.handlePassChange}
              />
              <Typography variant="inherit" color="textSecondary">
                
              </Typography>
              <DialogContentText>
              {t("sd_DCT2")}
              </DialogContentText>
              <br/>
              <Box className="mg20">
                    {currentFile && (
                      <Box className="mb25" display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                          <BorderLinearProgress variant="determinate" value={progress} />
                        </Box>
                        <Box minWidth={35}>
                          <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                        </Box>
                      </Box>)
                    }
                    <label htmlFor="btn-upload">
                      <input
                        id="btn-upload"
                        name="btn-upload"
                        style={{ display: 'none' }}
                        type="file"
                        onChange={this.selectFile} />
                      <Button
                        className="btn-choose"
                        alignItems="center"
                        variant="outlined"
                        color="success"
                        disabled={submitted}
                        component="span" >
                        {t("sd_B2")}
                      </Button>
                    </label>
                    <div className="file-name" >
                      <br/>
                      {selectedFiles && selectedFiles.length > 0 ? selectedFiles[0].name : null}
                    </div>

                    <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`} color={`${isError ? "red" : "green"}`}>
                    {t(message)}
                    </Typography>
              </Box>
              <br/>
              <DialogContentText>
              {t("sd_DCT3")}
              </DialogContentText>
        </DialogContent>

        <DialogActions>
        <Stack direction="row" spacing={2}>
        <Button onClick={this.handleClose} color="success">{t("sd_B3")}</Button>
        <Button
                      className="btn-upload"
                      color="success"
                      variant="contained"
                      component="span"
                      disabled={!(selectedFiles && (participant_id !== "") && (password !== ""))}
                      onClick={this.upload}
                      >
                      {t("sd_B4")}
                    </Button>
        </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );}
}}
export default SubmitDialog;