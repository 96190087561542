import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import i18n from '../i18n';
import Flag from 'react-world-flags'
export default function LangSelectToggle() {
  const [lng, setLang] = React.useState(i18n.language);

  const handleLang = (event) => {
    setLang(event.target.value);
    i18n.changeLanguage(event.target.value)
    //console.log(event.target.value)
    //console.log(i18n.language)
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={lng}
      exclusive
      label="Language"
      onChange={handleLang}
    >
      <ToggleButton value="en">(BSL) English &#8203;<Flag code={'gb' } height="16" sx={{ marginLeft:3}}/></ToggleButton>
      <ToggleButton value="jp">(手話) 日本語 &#8203;<Flag code={ 'jp' } height="16" sx={{ marginLeft:3}}/></ToggleButton>
    </ToggleButtonGroup>
  );
}