import CCard from '../components/CCard';
import AboutCCard from '../components/AboutCCard';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
/*
import { useState, useEffect } from 'react';
import axios from 'axios';
//var news_file = "https://www.cross-sign.nii.ac.jp/seafile/f/dfae00e239a2497489ea/?dl=1&reuse=1"
var news_file = "http://localhost:3000/test_news.txt"
*/
const StartPage = () => {
  const { t } = useTranslation();
  /*
  const [news, setNews] = useState('no new news');
  useEffect(() => {
    axios
        .get(news_file)
        .then((response) => {
          setNews(response.data);
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);
*/
  return (
      
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <AboutCCard card={{"text":t("cc1_h2"),"text_2":t("about_part_1"),"path":"/about","link":t("cc1_link")}}/>
          </Grid> 
          <Grid item xs={3}>
            <CCard card={{"text":t("cc2_h2"),"text_2":t("qq1_w"),"path":"/participant","link":t("cc2_link"),"marginTop":8, "target":"_self", "extern":false}}/>
            <CCard card={{"text":t("ev_ttl"),"text_2":t("ev_text"),"path":t("ev_link"),"link":t("ev_button"),"marginTop":3, "target":"_blank", "extern":true}}/>
          </Grid>
        </Grid>        


  );
}
export default StartPage;