import http from "../http-common";

class PostJoinusFormService {
    upload(formData) {
        console.log('Form Data:', formData);
    
        // Assuming formData is an object containing form fields
    
        // You might need to adjust the endpoint based on your server implementation
        return http.post("/joinsub", formData)
          .then(response => {
            console.log('Response:', response.data);
            return response.data;
          })
          .catch(error => {
            console.error('Error sending form data:', error);
            throw error;
          });
      }

}
export default new PostJoinusFormService();