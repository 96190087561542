import React, { Component } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, Stack, Typography } from '@mui/material';
import UploadService from "../services/upload-video";
import CheckPassword from "../services/pass_check";
import LinearProgress from '@mui/material/LinearProgress';
import { withStyles } from "@mui/styles";
import { useTranslation } from 'react-i18next';
import FileUploadIcon from '@mui/icons-material/FileUpload';
const SubmitForm = () => {
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 15,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: "#EEEEEE",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }))(LinearProgress);

  const { t } = useTranslation();

  return class extends Component {
    constructor(props) {
      super(props);
      this.selectFile = this.selectFile.bind(this);
      this.selectChatFile = this.selectChatFile.bind(this);
      this.upload = this.upload.bind(this);
      this.handleIdChange = this.handleIdChange.bind(this);
      this.handlePassChange = this.handlePassChange.bind(this);

    this.state = {
      currentFile: undefined,
      currentChatFile: undefined,
      progress: 0,
      message: "",
      isError: false,
      participant_id: "",
      password: "",
      submitted: false,
    };
  }
  
  handlePassChange(event) {
    this.setState({
      password: event.target.value,
      isError: false,
      message: "",
    });
  }
  
  handleIdChange(event) {
    this.setState({
      participant_id: event.target.value,
    });
  }

  selectFile(event) {
    this.setState({
      currentFile: event.target.files[0],
    });
  }

  selectChatFile(event) {
    this.setState({
      currentChatFile: event.target.files[0],
    });
  }

  upload(){
    //const upl = this.upload_cheked();
    CheckPassword.upload(this.state.participant_id, this.state.password)
      .then((response) => {
        console.log(response.data.message)
        if(response.status === 200){
          console.log("Correct")
          this.upload_cheked();
        }})

      .catch((error) => {
        console.log(error.response.data.message)
        this.setState({
          progress: 0,
          message: error.response.data.message,
          isError: true,
          password: "",
          submitted: false,
        });
        
        });
      }

      
 
  

  upload_cheked(){
    let currentFile = this.state.currentFile;
    let currentChatFile = this.state.currentChatFile;
    this.setState({
      progress: 0,
      submitted: true,
    });

    UploadService.upload(currentFile, currentChatFile, this.state.participant_id, this.state.password, (event) => {
      this.setState({
        progress: Math.round((100 * event.loaded) / event.total),
      });
    })
      .then((response) => {
        console.log(response.data.message)
        this.setState({
          message: response.data.message,
          isError: false
        });
      })
      .catch((error) => {
        console.log(error.response.data.message)
        this.setState({
          progress: 0,
          message: error.response.data.message,
          currentFile: undefined,
          currentChatFile: undefined,
          isError: true,
          participant_id: "",
          password: "",
          submitted: false,
        });
      });

  }

  render() {
    //const { t } = this.props;
    const {
      currentFile,
      currentChatFile,
      progress,
      message,
      isError,
      participant_id,
      password,
      submitted,
    } = this.state;

  return (

    <Box sx={{maxWidth:"60%", marginX:'auto' }}>
        <Box>
          <Typography>{t("sd_DCT1")}</Typography>
          <TextField
                required
                disabled={submitted}
                id="participant"
                name="part_id"
                value={participant_id}
                label={t("sd_L1")}
                fullWidth
                margin="dense"
                onChange={this.handleIdChange}
              />

        <TextField
          required
          disabled={submitted}
          id="password"
          name="password"
          value={password}
          label={t("sd_L2")}
          type="password"
          fullWidth
          margin="dense"
          onChange={this.handlePassChange}
        />
        <br/>
        <br/>
        <Typography variant="inherit" color="textSecondary">
        {t("sd_DCT2")}
        </Typography>
        <br/>
        <Box className="mg20">
          {submitted && (
            <Box className="mb25" display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                <BorderLinearProgress variant="determinate" value={progress} />
              </Box>
              <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
              </Box>
            </Box>)
          }
          <label htmlFor="btn-upload">
            <input
              id="btn-upload"
              name="btn-upload"
              style={{ display: 'none' }}
              type="file"
              accept=".mp4" 
              onChange={this.selectFile} />
            <Button
              className="btn-choose"
              alignItems="center"
              variant="outlined"
              color="success"
              disabled={submitted}
              component="span" >
              {t("sd_B2")}
            </Button>
          </label>
          <div className="file-name" >
            <br/>
            {currentFile ? currentFile.name : null}
          </div>

          <br/>
        
        <Typography variant="inherit" color="textSecondary">
        {t("sd_DCT2_chat")}
        </Typography>
        </Box>
        <br/>
        <Box className="mg20">
          <label htmlFor="btn-chat-upload">
            <input
              id="btn-chat-upload"
              name="btn-chat-upload"
              style={{ display: 'none' }}
              type="file"
              accept=".txt" 
              onChange={this.selectChatFile} />
            <Button
              className="btn-choose"
              alignItems="center"
              variant="outlined"
              color="success"
              disabled={submitted}
              component="span" >
              {t("sd_B2_chat")}
            </Button>
          </label>
          <div className="chat-file-name" >
            <br/>
            {currentChatFile ? currentChatFile.name : null}
          </div>

          <br/>
          <Typography variant="subtitle1" className={`upload-message ${isError ? "error" : ""}`} color={`${isError ? "red" : "green"}`}>
          {t(message)}
          </Typography>
        </Box>
        </Box>
        <br/>
        
        <Box>
        <Stack direction="row" spacing={2}>
          <Button
              className="btn-upload"
              color="success"
              variant="contained"
              component="span"
              disabled={!(currentFile && (participant_id !== "") && (password !== "") && !(submitted))}
              onClick={this.upload} startIcon={<FileUploadIcon/>}
              >
              {t("sd_B4")}
          </Button>
        </Stack>
        </Box>
    </Box>
  );}
}}
export default SubmitForm;