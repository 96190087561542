import { useTranslation } from 'react-i18next';
import { Box} from '@mui/material';
import JoinusForm from '../components/JoinusForm';

const Joinus = () => {
  const { t } = useTranslation();
  return (
    <div>
    <JoinusForm />
  </div>
  );
}
export default Joinus;