import Paper from '@mui/material/Paper';
//import Grid from '@mui/material/Grid';
//import { Button } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const CCard = ({card}) => {
    return( 
    
        <Paper elevation={5} sx={{ minHeight:200, padding:2, marginTop:card.marginTop }} >
        <Box  >
            <Box >
                <h2>{card.text}</h2>
            </Box>
            <Box sx={{ paddingY:2 }} >

                <h3>
                {card.text_2}
                </h3>

                <Box sx={{width:"11em", marginX:'auto', marginTop:'20%',textAlign:'center'}}>
                    <Button variant="contained" target={card.target} href={card.path} size="large" color="success" sx={{marginX:'auto'}} endIcon={(card.extern) ? <OpenInNewIcon/> : <NavigateNextIcon/>}>
                        {card.link}
                    </Button>
                </Box>
            </Box>
    
        </Box>
      
        </Paper>

    );
};

export default CCard;