import http from "../http-common";

class UploadFilesService {
  upload(file, chatfile, participant_id, password, onUploadProgress) {
      let formData = new FormData();
      formData.append("file", file);
      var chat = "0"
      if (chatfile !== undefined){
        formData.append("file", chatfile);
        chat="1";
      }
      //console.log(formData);
      return http.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "password": password,
          "participant_id": participant_id,
          "init":"0",
          "chat":chat,
        },
        onUploadProgress,});}

}
export default new UploadFilesService();