import React from "react";

import { Box, Typography} from '@mui/material';

const Terms = () => {

    return (
            
            <Box >
                 <Typography variant="h5" >実験参加同意書
                </Typography>
                <br/>
                <Typography>
                （本資料は日本手話動画・書記日本語がございます）
                </Typography>
                <br/>
                <Typography  >
                国立情報学研究所とヘリオットワット大学(英国スコットランド)は、コロナ禍におけるろう者のオンライン対話およびそのコミュニケーションの様子を研究しています。この研究活動の一環として、(1) 皆さんがZoomなどのオンライン会議システムを用いて会話されている実際の様子（以下、オンライン対話）、(2)コロナ禍におけるろう者のコミュニケーションの変容やオンライン会議に対する印象などに関するインタビュー調査対話（以下、インタビュー調査対話）を研究資料として収録する計画を進めております。この同意書は、(1)オンライン対話と(2)インタビュー調査対話を対象にしたもので（説明書に記載されていた大規模アンケート調査は含まれません）。撮影した映像および書き起こし資料、生育環境や言語獲得環境等の個人情報（以下、収録データ）は研究目的にのみ利用し、研究代表者が承認した大学や研究機関等への提供を予定しています。みなさまのご理解とご協力を賜り、収録に参加されることを切にお願い申し上げます。
                </Typography>
                <br/>
                <Typography >
                研究課題：　コロナ禍/コロナ後におけるオンライン会議状況でのクロスサイ
                ニング現象の理解
                研究代表者：(日本)情報・システム研究機構国立情報学研究所准教授　坊農真弓
                研究代表者：(英国)ヘリオット・ワット大学助教　ロバート・アダム
                </Typography>
                <br/>
                <Box sx={{ marginLeft:5}}>
                <ul>
                    <li><Typography >オンライン対話の一回の参加に要する時間は、最短で5分、最長でも15分程度です。</Typography></li>
                    <li><Typography >インタビュー調査対話の一回の参加に要する時間は、最短で15分、最長でも60分程度です。</Typography></li>
                    <li><Typography >撮影中、参加されたみなさまが不利益や危険を被ることはありません。</Typography></li>
                </ul>
                </Box>

                <br/>
                <Typography sx={{ fontWeight:600}}>実験への参加について</Typography>
                <Box sx={{ marginLeft:5}}>
                
                <ol type="1">
                    <li><Typography >この実験への参加は、みなさまの任意のご協力によるものです。</Typography></li>
                    <li><Typography >撮影の途中で、参加を中断し、また取りやめることも自由です。</Typography></li>
                    <li><Typography >オンライン対話とインタビュー調査の映像の削除権利は半永久的に皆さんにあります。「半永久的」というのは、論文や学会発表や書籍出版等で発表済みのものは、著作権が著者および出版社のものとなることがあるため、削除することができない場合があることを意味します。</Typography></li>
                    <li><Typography >撮影後は収録データを研究終了後10年間保管します。その際、NII RESEARCH DATA CLOUD (RDC)等のプラットフォームを利用します。</Typography></li>
                    <li><Typography >撮影後は本同意書を15年間保管します。参加された皆さまの同意および同意書削除依頼があった場合は直ちに削除します。</Typography></li>
                </ol>
                </Box>
                <br/>
                <Typography sx={{ fontWeight:600}}>研究者側による収録データの利用について</Typography>
                <Box sx={{ marginLeft:5}}>
                
                <ol type="1">
                    <li><Typography >収録データは、学術研究にのみ使用します。</Typography></li>
                    <li><Typography >収録データは、「情報・システム研究機構個人情報保護規程」（http://www. rois.ac.jp/pdf/3-10.pdf）に基づいて国立情報学研究所が責任をもって管理します。収録データに写り込んだ個人情報および著作物については、必要に応じてボカシ処理を行う、当該箇所のデータを削除するなどして、個人情報の保護および著作権侵害の防止に努めます。</Typography></li>
                    <li><Typography >参加者から保有個人情報および収録データの開示請求があった場合には、すみやかに該当情報およびデータの開示を行います。</Typography></li>
                    <li><Typography >万が一、個人情報の漏えい等が発生し、個人の権利利益を害するおそれが大きいと判断される場合にはデータの利用を中止し、すみやかに国立情報学研究所個人情報保護委員会および参加者本人に通知します。</Typography></li>
                </ol>
                </Box>
                <br/>
                <Typography sx={{ fontWeight:600}}>研究者側による収録データの第三者提供について</Typography>
                <Box sx={{ marginLeft:5}}>
                
                <ol type="1">
                    <li><Typography >収録データの一部は、学術研究目的に限定し、第三者提供します。</Typography></li>
                    <li><Typography >収録データ提供に際し、氏名は表示しませんが、居住地区、教育を受けた場所、成育地、家庭内の言語環境等は資料として第三者に提供する可能性があります。</Typography></li>
                    <li><Typography >収録データは、国立情報学研究所及びヘリオット・ワット大学などの信頼できる学術研究機関に委託して、研究代表者が承認し、契約を交わした大学や研究機関等にのみに提供します。</Typography></li>
                    <li><Typography >第三者は日本国内に留まらず、英国をはじめとする世界中の大学や研究機関等となることが予想されます。日英の研究代表者（坊農とアダム）が承認した大学や研究機関等は、NII-IDRが連絡先等を管理します。提供先は基本的に公表はしませんが、実験に参加いただいたみなさんのご希望があれば情報開示するよう努めます。</Typography></li>
                    <li><Typography >収録後、発話内容に個人情報が多く含まれると判断されたデータは、映像のボカシ等の個人情報保護処理を実施するか、第三者提供を取りやめます。</Typography></li>
                    <li><Typography >万が一、個人情報の漏えい等が発生し、個人の権利利益を害するおそれが大きいと判断される場合にはデータの第三者提供を中止し、すみやかに国立情報学研究所の担当部署および参加者本人に通知します。</Typography></li>
                </ol>
                </Box>
                <Typography >以上</Typography>
                <br/>


            </Box>
           );
    };

export default Terms;