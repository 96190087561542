export const man_items = [
    {
      'title': '「ご挨拶・全体説明」',
      'text': 'みなさん、こんにちは。COVID-19の世界的大流行により、世界中のろう者がテレビ会議システムを介してオンラインコミュニケーションをとることが日常的になりました。この状況は、手話言語同士の接触を誘発し、新しい手話語彙や手話表現の出現を巻き起こす可能性があります。本研究の目的は、オンラインコミュニケーションによって、ろう者の手話やコミュニケーションスタイルがどのような影響を受けるのかを、手話言語として全く異なるイギリス手話（BSL）と日本手話（JSL）、また手話表現の地域差に着目して調査することです。例えば、異なる手話言語を用いるろう者同士は、言語の違いをどのように乗り越えているのでしょうか、また異なる地域の手話表現を用いるろう者同士は、方言の違いをどのように乗り越えているのでしょうか。私たちの研究グループは、ろう者のおしゃべりの方略（ストラテジー）を理解し、会話における言語使用の理論的基礎（theoretical foundation of language use in conversation)を提案することを目指しています。将来的に本研究の成果は、世界中のろう者が自由におしゃべりしたり、議論したりする環境を構築する上で参考になる点が多々あると考えています。',
      'video_id': 'x11NO-Mefk4',
    },
    {
        'title': '「研究の体制」',
        'text': '本研究は2021年12月から2024年11月までの3年間、文部科学省の国際共同研究事業「英国との国際共同研究プログラム」の一環として実施されています。研究課題名は「コロナ禍/コロナ後におけるオンライン会議状況でのクロスサイニング現象の理解」といいます。日本側の研究代表者は坊農真弓（聴者、国立情報学研究所）、イギリス側の研究代表者はロバート・アダム（ろう者、ヘリオットワット大学、英国スコットランド)が務めています。',
        'video_id': 'c9NkzzGAQco',
    },
    {
        'title': '「インタビュー調査対話（詳細・時期未定）」',
        'text': 'オンライン対話の分析結果が出始めた段階で、クロスサインとトランスランゲージングに関係するインタビュー調査を実施する計画があります。インタビューは、こちらが用意したろうのインタビュワーが一対一もしくは一対多で事前に用意した項目について質問をします。詳細と時期は現在検討中です。',
        'video_id': 'XeXNNnjWhAQ',
    },
    {
        'title': '「大規模アンケート調査（詳細・時期未定）」',
        'text': '次に、今回ご協力いただくみなさんに限らない100人程度の不特定多数のろうの方を対象に、Google formを用い、パンデミック前・中・後の言語意識についてアンケート調査を実施する計画があります。これを「大規模アンケート調査」と呼びます。こちらも詳細と時期は現在検討中です。今回の実験参加同意書にはこちらへの参加の同意は含まれませんのでご注意ください。',
        'video_id': 'D4LkNL3lMlM',
    },
    {
        'title': '「研究方法・分析の例」',
        'text': '収録されたオンライン対話とインタビュー調査対話の録画データを用い、どのようにコミュニケーションをするのかを観察していきます。具体的にはクロスサインとトランスランゲージングに焦点を当て、会話分析(CA)を使ってデータを詳しく見ていきます。会話相手の発話内容を理解するために、異なる地域の手話表現を用いるろう者や、異なる手話言語を用いるイギリスと日本のろう者が対話の中でどのように、「通じない」問題解決していくのかを観察していきます。',
        'video_id': 'DsDA3oxKgqA',
    },
    {
        'title': '「人工知能研究応用」',
        'text': 'オンライン対話およびインタビュー調査対話の録画データは、すべてクロスサインの言語コーパスとして、日本とイギリスのデータサーバーに保管されます。録画データは言語コミュニケーション研究に用いられるだけではなく、手話言語の機械学習（または人工知能）に利用されます。こういった人工知能研究への応用を経て、コンピュータで手話の動きや手の形を検出することができるようになり、手話翻訳技術が発展する可能性があります。',
        'video_id': 'pjgf7C-t1uU',
    },
    {
        'title': '「収録時間のめやす」',
        'text': 'オンライン対話の一回の参加に要する時間は、最短で5分、最長でも15分程度です。インタビュー調査の一回の参加に要する時間は、最短で15分、最長でも60分程度です。オンライン対話収録は、収録期間と回数が多少複雑なので次で詳しく説明します。',
        'video_id': 'xX-qVQ8tTIQ',
    },
    {
        'title': '「オンライン対話・収録期間と回数（1年目）」',
        'text': 'オンライン対話は1年目(2022年度）は、あなたとは異なる地域に住む日本手話話者2名とそれぞれ1回ずつ対話していただきます。あなたが対話するのは合計月に2回です。話す内容は事前に私たちが提案しますが、多少脱線しても構いません。話終わりの目安（時間）は上記のとおりですが、それ以上長く話していただいても構いません。この対話収録は6ヶ月続きますので、6ヶ月間で12回オンライン対話することになります。',
        'video_id': 'NVk-XurH63I',
    },

];