import http from "../http-common";

class CheckPassword {
  upload(participant_id, password) {
    let fData = new FormData();
    return http.post("/upload",fData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "password": password,
        "participant_id": participant_id,
        "init":"1",
      }})
  }

}
export default new CheckPassword();